import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Form, Row, Col, message, Upload } from 'antd';
import { editClientCompany, updateClient } from '../api/client';
import { updateRecruiter } from '../api/recruiter';
import { changePassword } from '../api/user';
import Button from '../UI/buttons/Button';
import InputPassword from '../UI/inputs/InputPassword';
import FormTitle from '../components/auth/FormTitle';
import SettingsMenu from '../components/settings/SettingsMenu';
import PersonalInfo from '../components/form/shared/PersonalInfo';
import FormItem from '../components/form/FormItem';
import { useAppSelector, useAppDispatch } from '../store/hooks';
import { getUserByToken } from '../store/userSlice';
import { passwordRule, requiredRule } from '../utils/validationRules';
import { getToken } from '../utils/localStorage';
import PersonalInfoRecruiter from '../components/form/shared/PersonalInfoRecruiter';
import { CLIENT_ROLE, RECRUITER_ROLE } from '../constants/roles';
import { ReactComponent as EditIcon } from '../icons/edit.svg';
import { ReactComponent as JobIcon } from '../icons/jobs.svg';
import { RcFile } from 'antd/lib/upload';
import ReactGa from 'react-ga4';

const PageWrapper = styled.section`
  background: ${({ theme }) => theme.lozengeColor};
  width: 100%;
  display: flex;
  min-height: 100vh;
  flex-flow: row-reverse;
`;

const FormWrapper = styled.div`
  background: #fff;
  width: 61%;
  padding: 4.5rem 4rem;
  display: flex;
  flex-direction: column;
  position: relative;

  @media (max-width: 1200px) {
    width: 70%;
  }
  @media (max-width: 768px) {
    width: 90%;
    padding: 3rem;
  }
`;

const StyledForm = styled(Form)`
  margin-top: 2.5rem;
  width: 100%;
`;

const FormSubtitle = styled.h2`
  font-family: Lato;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #061c2e;
  margin: 1.5rem 0;
`;

const StyledUpload = styled(Upload)`
  & .ant-upload {
    display: flex;
    align-items: center;
  }
`;

const CompanyLogo = styled.div`
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background: #627d95 no-repeat center;
  &.bg-white {
    background: #ffffff no-repeat center;
  }
  & img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    border-radius: 50%;
  }
`;

const FormatHint = styled.div`
  font-size: 0.75rem;
  line-height: 1.33;
  text-align: left;
  color: #627d95;
  margin-left: 1rem;
`;

const StyledIcon = styled(JobIcon)`
  color: #aebeca;
  width: 3rem;
  height: 3rem;
`;

const ImageUploadButton = styled.div`
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.lozengeColor};
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.primaryColor};
  position: absolute;
  bottom: 2px;
  right: 0;
  z-index: 9;
`;

const PasswordHint = styled.p`
  font-size: 0.625rem;
  color: #627d95;
  margin: 0.5rem 0 1.5rem;
`;

const ProfilePage = () => {
  const [loading, setLoading] = useState(false);
  const [passwordLoading, setPasswordLoading] = useState(false);
  const [phone, setPhone] = useState('');
  const [fileError, setFileError] = useState<string>('');
  const [form] = Form.useForm();
  const [changePasswordForm] = Form.useForm();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  const company = user?.company || '';
  const isClient = user?.role === CLIENT_ROLE;
  const isRecruiter = user?.role === RECRUITER_ROLE;
  let image = '';
  if (isClient) {
    image = company ? company.image : '';
  }
  if (user && isRecruiter) {
    image = user?.image || '';
  }
  const [logo, setLogo] = useState<string>(image);
  const { t } = useTranslation();
  useEffect(() => {
    ReactGa.pageview('/profile-settings');
  }, []);

  const handleUploadFile = useCallback(
    (file: RcFile): boolean => {
      if (file.type === 'image/png' || file.type === 'image/jpeg') {
        setFileError('');
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            const base64 = reader.result;
            if (base64) {
              setLogo(base64?.toString());

              isClient &&
                form?.setFieldsValue({
                  ...form.getFieldsValue(),
                  image: base64.toString(),
                });
            }
          };
          reader.onerror = (error) => reject(error);
        });
      } else {
        setFileError(t('FILE_FORMAT_VALIDATION'));
      }
      return false;
    },
    [form, isClient, t],
  );

  useEffect(() => {
    let image;
    if (isClient) {
      image = company ? company.image : '';
    }
    if (isRecruiter) {
      image = user?.image;
    }
    if (user) {
      form.setFieldsValue({
        name: user.name,
        title: user.title,
        recruiter_type: user.recruiter_type,
        company: user.company?.name,
        department: user.department,
        email: user.email,
        image: image,
        number_of_employees: user.company?.number_of_employees,
        industry_experiences: user?.industry_experiences,
      });
      setPhone(user.phone);
    }
  }, [user, form, company, isRecruiter, isClient]);

  const onUpdateClient = (values: any) => {
    setLoading(true);
    if (logo.startsWith('http')) {
      delete values.image;
    } else if (!logo) {
      delete values.image;
    } else {
      company && editClientCompany({ image: values.image }, company.id);
      delete values.image;
    }

    updateClient({ ...values, phone })
      .then(async (res) => {
        setLoading(false);
        const token = getToken('access_token');
        dispatch(getUserByToken(token));
        message.success(t('INFORMATION_SAVE_SUCCESS'), 3);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.toString(), 3);
      });
  };

  const onUpdateRecruiter = (values: any) => {
    setLoading(true);
    if (logo.startsWith('http')) {
      delete values.image;
    } else if (!logo) {
      delete values.image;
    } else {
      values.image = values.image.file;
    }
    const formData = new FormData();
    Object.keys(values).forEach((el) => {
      formData.append(el, values[el]);
    });
    formData.append('phone', phone ?? '');
    updateRecruiter(formData)
      .then(async (res) => {
        setLoading(false);
        const token = getToken('access_token');
        dispatch(getUserByToken(token));
        message.success(t('INFORMATION_SAVE_SUCCESS'), 3);
      })
      .then((res) => {
        updateRecruiter({
          about_company: values.about_company,
          description: values.description,
          preferable_specializations: values.preferable_specializations,
        });
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.toString(), 3);
      });
  };

  const onChangePasswordFinish = (values: any) => {
    setPasswordLoading(true);
    changePassword(values)
      .then(async (res) => {
        setPasswordLoading(false);
        const token = getToken('access_token');
        dispatch(getUserByToken(token));
        message.success(res.data.detail, 3);
        changePasswordForm.resetFields();
      })
      .catch((err) => {
        setPasswordLoading(false);
        message.error(err.response.data.message, 3);
        changePasswordForm.resetFields();
      });
  };
  return (
    <PageWrapper>
      <FormWrapper>
        <SettingsMenu menu="profile" />
        <StyledForm
          scrollToFirstError={true}
          layout="vertical"
          name="invitation"
          onFinish={isClient ? onUpdateClient : onUpdateRecruiter}
          form={form}
        >
          <FormTitle text={t('PROFILE_SETTINGS')} />
          {isClient && (
            <>
              <FormItem
                label={t('COMPANY_LOGO')}
                name="image"
                rules={requiredRule(t)}
              >
                <StyledUpload
                  accept="image/png, image/jpeg, image/jpg"
                  beforeUpload={handleUploadFile}
                  showUploadList={false}
                >
                  <CompanyLogo className={logo ? 'bg-white' : ''}>
                    {logo ? <img src={logo} alt="" /> : <StyledIcon />}
                    <ImageUploadButton>
                      <EditIcon />
                    </ImageUploadButton>
                  </CompanyLogo>
                  <FormatHint>PNG or JPG</FormatHint>
                </StyledUpload>
              </FormItem>
              {fileError && (
                <div className="ant-form-item-explain ant-form-item-explain-error">
                  <div role="alert">{fileError}</div>
                </div>
              )}
              <PersonalInfo
                form={form}
                disableFields={true}
                disableDepartment={isClient}
                setPhone={setPhone}
                phone={phone}
              />
            </>
          )}
          {isRecruiter && (
            <>
              <FormItem label={t('PROFILE_IMAGE')} name="image">
                <StyledUpload
                  accept="image/png, image/jpeg, image/jpg"
                  beforeUpload={handleUploadFile}
                  showUploadList={false}
                >
                  <CompanyLogo className={logo ? 'bg-white' : ''}>
                    {logo ? <img src={logo} alt="" /> : <StyledIcon />}
                    <ImageUploadButton>
                      <EditIcon />
                    </ImageUploadButton>
                  </CompanyLogo>
                  <FormatHint>PNG or JPG</FormatHint>
                </StyledUpload>
              </FormItem>
              {fileError && (
                <div className="ant-form-item-explain ant-form-item-explain-error">
                  <div role="alert">{fileError}</div>
                </div>
              )}
              <PersonalInfoRecruiter
                form={form}
                setPhone={setPhone}
                phone={phone}
                city={user?.city?.id}
              />
            </>
          )}
          <FormItem>
            <Button type="primary" htmlType="submit" loading={loading}>
              {t('SAVE_TITLE')}
            </Button>
          </FormItem>
        </StyledForm>

        <StyledForm
          layout="vertical"
          name="invitation"
          onFinish={onChangePasswordFinish}
          form={changePasswordForm}
        >
          <FormSubtitle>{t('CHANGE_PASSWORD')}</FormSubtitle>
          <FormItem>
            <Row>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 11 }}
                md={{ span: 24 }}
                lg={{ span: 11 }}
              >
                <FormItem
                  label={t('CURRENT_PASSWORD')}
                  name="old_password"
                  rules={passwordRule(t)}
                >
                  <InputPassword />
                </FormItem>
              </Col>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 11, offset: 2 }}
                md={{ span: 24, offset: 0 }}
                lg={{ span: 11, offset: 2 }}
              >
                <FormItem
                  label={t('NEW_PASSWORD')}
                  name="new_password"
                  rules={passwordRule(t)}
                  className="no-margin"
                >
                  <InputPassword />
                </FormItem>
                <PasswordHint>{t('PASSWORD_HINT')}</PasswordHint>
              </Col>
            </Row>
          </FormItem>
          <FormItem>
            <Button type="primary" htmlType="submit" loading={passwordLoading}>
              {t('CHANGE_PASSWORD')}
            </Button>
          </FormItem>
        </StyledForm>
      </FormWrapper>
    </PageWrapper>
  );
};

export default ProfilePage;
