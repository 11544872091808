import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Tabs } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import CandidatePersonalInfo from './CandidatePersonalInfo';
import { CandidateHistory, CandidateInfo } from '../../types';
import GeneralInfo from './GeneralInfo';
import SalaryPackage from './SalaryPackage';
import FormPopUp from '../form/FormPopUp';
import Button from '../../UI/buttons/Button';
import FormItem from '../form/FormItem';
import { ReactComponent as EditIcon } from '../../icons/edit.svg';
import Tab from '../../UI/Tab';
import { getCandidateHistory } from '../../api/candidates';
import HistoryList from './HistoryList';
import { useHistory } from 'react-router-dom';
import { CANDIDATES } from '../../constants/routes';
import { navigate } from '../../utils/routes';
import InfoItem from './InfoItem';
import { useAppSelector } from '../../store/hooks';
import getRecruiterFeeSummary from '../../utils/getRecruiterFeeSummary';
import useCurrency from '../../hooks/useCurrency';

interface CandidateInfoProps {
  current: CandidateInfo | null;
  setCurrent: React.Dispatch<React.SetStateAction<CandidateInfo | null>>;
}

const StyledButton = styled(Button)`
  text-transform: capitalize;
  margin-top: 1rem;
`;

const StyledForm = styled(Form)`
  margin-top: 4rem;
`;

const Info = styled.p`
  font-size: 0.875rem;
  line-height: 1.71;
  color: #061c2e;
  background-color: #ffffff;
  margin: 0;
  white-space: pre-line;
`;

const { TabPane } = Tabs;
const CandidateHistoryModal = ({ current, setCurrent }: CandidateInfoProps) => {
  const [visible, setVisible] = useState(!!current);
  const [history, setHistory] = useState<CandidateHistory[]>([]);
  const routerHistory = useHistory();
  const params = new URLSearchParams(routerHistory.location.search);
  const filter = params.get('filter');
  const candidate = params.get('history');
  const [t] = useTranslation();
  const currency = useCurrency(current?.compensation_currency);
  const { user } = useAppSelector((state) => state.user);
  const recruiterFeeSummary = current ? getRecruiterFeeSummary(
    t,
    currency,
    current,
    false,
  ) : null;

  useEffect(() => {
    setVisible(!!current);
  }, [current]);

  useEffect(() => {
    if (!visible) {
      navigate(routerHistory, undefined, [
        'history',
        'interview_id',
        'stage_name',
      ]);
    } else {
      candidate &&
        getCandidateHistory(candidate, routerHistory).then((res) =>
          setHistory(res.data),
        );
    }
  }, [visible, setCurrent, candidate, routerHistory]);

  const onEdit = (values: any) => {
    params.delete('history');
    params.delete('interview_id');
    current && params.set('edit', current.id);
    setCurrent(null);
    routerHistory.push(`${CANDIDATES}/?${params.toString()}`);
  };

  return (
    <FormPopUp
      visible={visible}
      setVisible={setVisible}
      title={t('CANDIDATE_INFO')}
      large={true}
    >
      <CandidatePersonalInfo
        name={current?.name || ''}
        color={current?.color}
        role={current?.current_role}
        company={`${t('AT')} ${current?.current_company && current?.current_company
          }`}
        cv={current?.cv as string}
        linkedin={current?.linkedin_link as string}
      />
      <Tab defaultActiveKey="1" className="mt-5">
        <TabPane tab={t('CANDIDATE_INFO')} key="1">
          <Row justify="space-between">
            <Col xs={{ span: 24 }} lg={{ span: 11 }} xl={{ span: 10 }}>
              <GeneralInfo candidate={current as CandidateInfo} />
            </Col>
            {user?.hide_salary_info ? (
              <></>
            ) : (
              <Col xs={{ span: 24 }} lg={{ span: 11 }} xl={{ span: 10 }}>
                <SalaryPackage candidate={current as CandidateInfo} />
              </Col>
            )}
          </Row>
          <StyledForm
            onFinish={onEdit}
            layout="vertical"
            scrollToFirstError={true}
          >
            {current?.interviews[0] && (
              <>
                <FormItem
                  label={`${t('INTRODUCTION')}`}
                  className={'text-grey'}
                >
                  <Info>{current?.interviews[0].introduction}</Info>
                </FormItem>

                <FormItem label={`${t('WHY_MATCH')}`} className={'text-grey'}>
                  <Info>{current?.interviews[0].match_reason}</Info>
                </FormItem>

                <FormItem
                  label={`${t('REASON_NEXT_STEP')}`}
                  className={'text-grey'}
                >
                  <Info>{current?.interviews[0].next_step_reason}</Info>
                </FormItem>
              </>
            )}
            {current?.additional_info && (
              <FormItem
                label={`${t('ADDITIONAL_INFO')}`}
                className={'text-grey'}
              >
                <Info>{current?.additional_info}</Info>
              </FormItem>
            )}
            {!filter && (
              <FormItem>
                <StyledButton
                  size="large"
                  type="primary"
                  htmlType="submit"
                  icon={<EditIcon />}
                >
                  {t('EDIT_TITLE')}
                </StyledButton>
              </FormItem>
            )}
          </StyledForm>
        </TabPane>
        <TabPane tab={t('HISTORY')} key="2">
          <HistoryList history={history} />
        </TabPane>
        {current && recruiterFeeSummary?.compensationType && (
          <TabPane tab={t('CANDIDATE_JOB_RECRUITER_FEES')} key="3">
            <Row>
              <Col xs={{ span: 24 }} lg={{ span: 11 }} xl={{ span: 10 }}>
                <InfoItem
                  title={t('CANDIDATE_JOB_RECRUITER_COMPENSATION_TYPE')}
                  result={
                    recruiterFeeSummary.compensationType
                      .charAt(0)
                      .toUpperCase() +
                    recruiterFeeSummary.compensationType.slice(1)
                  }
                />
              </Col>
            </Row>
            <Col xs={{ span: 24 }} lg={{ span: 11 }} xl={{ span: 10 }}>
              <InfoItem
                title={t('CANDIDATE_JOB_RECRUITER_COMPENSATION_TYPE_TOOLTIP')}
                result={recruiterFeeSummary.recruiterFee}
              />
            </Col>
            {!!current?.guarantee_period && (
              <Col xs={{ span: 24 }} lg={{ span: 11 }} xl={{ span: 10 }}>
                <InfoItem
                  title={t('COMPANY_AGREEMENT_GUARANTEE_PERIOD')}
                  result={current?.guarantee_period}
                />
              </Col>
            )}
            {current?.payment_terms && (
              <Col xs={{ span: 24 }} lg={{ span: 11 }} xl={{ span: 10 }}>
                <InfoItem
                  title={t('COMPANY_AGREEMENT_PAYMENT_TERMS')}
                  result={current?.payment_terms}
                />
              </Col>
            )}
            {current?.restitution && (
              <Col xs={{ span: 24 }} lg={{ span: 11 }} xl={{ span: 10 }}>
                <InfoItem
                  title={t('COMPANY_AGREEMENT_RESTITUTION')}
                  result={current?.restitution}
                />
              </Col>
            )}
            {current.restitution_payment_terms && (
              <Col xs={{ span: 24 }} lg={{ span: 11 }} xl={{ span: 10 }}>
                <InfoItem
                  title={t('COMPANY_AGREEMENT_RESTITUTION_PAYMENT_TERMS')}
                  result={current?.restitution_payment_terms}
                />
              </Col>
            )}
            {current.replacement_time && (
              <Col xs={{ span: 24 }} lg={{ span: 11 }} xl={{ span: 10 }}>
                <InfoItem
                  title={t('COMPANY_AGREEMENT_REPLACEMENT_TIME')}
                  result={current?.replacement_time}
                />
              </Col>
            )}
            {(current.exclusivity_for_replacement === true ||
              current.exclusivity_for_replacement === false) && (
                <Col xs={{ span: 24 }} lg={{ span: 11 }} xl={{ span: 10 }}>
                  <InfoItem
                    title={t('COMPANY_AGREEMENT_EXCLUSIVITY_FOR_REPLACEMENT')}
                    result={
                      current?.exclusivity_for_replacement === true
                        ? t('YES')
                        : t('NO')
                    }
                  />
                </Col>
              )}
          </TabPane>
        )}
      </Tab>
    </FormPopUp>
  );
};

export default CandidateHistoryModal;
