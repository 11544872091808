import React, { useEffect, useState } from 'react';
import FormTitle from '../auth/FormTitle';
import {
  Checkbox,
  Col,
  Form,
  message,
  Row,
  Select,
  Tree,
  TreeProps,
} from 'antd';
import { getRegions, setCityList } from '../../api/regions';
import { getSeniorityList, setSeniorityList } from '../../api/seniority';
import {
  getSpecializationList,
  setSpecializationList,
} from '../../api/specialization';
import { CaretDownOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import FormItem from '../form/FormItem';
import Button from '../../UI/buttons/Button';
import { requiredRule } from '../../utils/validationRules';
import axios from 'axios';
import { updateRecruiter, getRecruiterInfo } from '../../api/recruiter';
import { getUserByToken, updateUser } from '../../store/userSlice';
import { DASHBOARD } from '../../constants/routes';
import { useAppDispatch } from '../../store/hooks';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CheckboxInput from '../../UI/inputs/CheckboxInput';
import { getUserInfo } from '../../api/user';
import { getToken } from '../../utils/localStorage';
import Loader from '../../UI/Loader';
import type { DataNode } from 'antd/es/tree';
import { Recruiter, Specialization } from '../../types';
import {
  FLEX,
  FREELANCE,
  INTERIM_JOBS,
  PERM,
  SECONDMENT,
} from '../../constants/common';
import Subtitle from '../../UI/typography/Subtitle';
import SelectInput from '../../UI/inputs/Select';
import StyledSlider from '../../UI/inputs/Slider';
import { getGooglePlacesTextSearch } from '../../api/google';
import debounce from 'lodash/debounce';

const { Option } = Select;

interface InfoProps {
  id: string;
  name: string;
}

const DEFAULT_EMPLOYMENT_TYPES = [
  FREELANCE,
  PERM,
  INTERIM_JOBS,
  FLEX,
  SECONDMENT,
];

const StyledCheckBoxGroup = styled(Checkbox.Group)`
  width: 100%;
`;
const StyledCheckBoxItem = styled(CheckboxInput)`
  width: 100%;
  margin-bottom: 0.5rem;
  padding-right: 1rem;
`;
const StyledRow = styled(Row)`
  margin-top: 2.5rem;
`;
const StyledSectionTitle = styled.p`
  margin-bottom: 1rem;
  font-size: 0.625rem;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: 0.8px;
  color: #061c2e;
  text-transform: uppercase;
`;
const StyledButton = styled(Button)`
  margin-top: 1rem;
`;

const StyledTree = styled.div`
  & .ant-tree-checkbox-inner {
    width: 1.125rem;
    height: 1.125rem;
    border: 1px solid #aebeca;
    border-radius: 0.2rem;
    &:after {
      left: 24%;
    }
  }

  & .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
    background-color: #627d95;
    border-color: #627d95;
    border-radius: 0.2rem;
  }

  &:hover::after,
  &:hover .ant-tree-checkbox::after {
    visibility: hidden;
  }

  & .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
    background-color: #627d95;
    left: 50%;
  }

  & .ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
    border-color: #627d95;
    border-radius: 0.2rem;
    &::after {
      left: 24%;
    }
  }

  & .ant-tree-checkbox-input:hover {
    & + .ant-tree-checkbox-inner {
      border-color: ${({ theme }) => theme.activeColor};
      border-radius: 0.2rem;
    }
  }

  & .ant-tree-checkbox-checked {
    & .ant-tree-checkbox-input:hover {
      & + .ant-tree-checkbox-inner {
        background-color: ${({ theme }) => theme.activeColor};
        border-radius: 0.2rem;
      }
    }
  }

  & .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background-color: #ffffff;
  }
`;

const RecruiterOnboard = ({ user, standalone, isOnboard = false }: any) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [pageLoad, setPageLoad] = useState(true);
  const [regions, setRegions] = useState<InfoProps[]>([]);
  const [treeData, setTreeData] = useState<DataNode[]>([]);
  const [defaultCheckedKeys, setDefaultCheckedKeys] = useState<string[]>([]);
  const [seniority, setSeniority] = useState<InfoProps[]>([]);
  const [specializations, setSpecializations] = useState<InfoProps[]>([]);
  const [currentCheckedKeys, setCurrentCheckedKeys] = useState<string[]>([]);
  const [selectedEmploymentTypes, setSelectedEmploymentTypes] = useState<
    string[]
  >([]);

  const [googlePlaces, setGooglePlaces] = useState<
    {
      formattedAddress?: string;
      displayName: {
        text?: string;
        languageCode?: string;
      };
    }[]
  >([]);
  const [candidateNetworkLocation, setCandidateNetworkLocation] =
    useState<string>('');
  const [fetching, setFetching] = useState(false);
  const [form] = Form.useForm();

  function buildDataNode(domain: Specialization): DataNode {
    const dataNode: DataNode = {
      key: domain.id,
      title: domain.name,
    };

    if (domain.sub_domains && domain.sub_domains.length > 0) {
      dataNode.children = domain.sub_domains.map((subDomain: any) =>
        buildDataNode(subDomain),
      );
    }

    return dataNode;
  }

  function traverseSpecializations(
    specializations: Specialization[],
    defaultCheckedKeys: string[],
    defaultOnboardingChecked: boolean,
  ) {
    const userSpecializations = user.specializations;
    specializations.forEach((specialization) => {
      if (
        userSpecializations.find((el: any) => el.id === specialization.id) ||
        defaultOnboardingChecked
      ) {
        if (
          !defaultCheckedKeys.find((el: any) => el.id === specialization.id)
        ) {
          defaultCheckedKeys.push(`${specialization.id}`);
        }
      }

      // Traverse any sub-domains recursively
      if (specialization.sub_domains && specialization.sub_domains.length > 0) {
        traverseSpecializations(
          specialization.sub_domains,
          defaultCheckedKeys,
          defaultOnboardingChecked,
        );
      }
    });

    return defaultCheckedKeys;
  }

  const setSpecializationStructuredData = (specializations: []) => {
    const userSpecializations = user.specializations;
    let defaultCheckedKeys: any = [];
    for (let domain of specializations) {
      // @ts-ignore
      if (domain.sub_domains.length) {
        // @ts-ignore
        for (let subDomain of domain.sub_domains) {
          // @ts-ignore
          specializations = specializations.filter(
            // @ts-ignore
            (domain) => domain.id !== subDomain.id,
          );
        }
      }
    }

    let treeData: any = [];
    const defaultOnboardingChecked =
      !user.specializations.length && user.onboarding_status === 'in progress';

    for (let i = 0; i < specializations.length; i++) {
      let specialization = specializations[i] as Specialization;
      if (
        userSpecializations.find((el: any) => el.id === specialization.id) ||
        defaultOnboardingChecked
      ) {
        if (
          !defaultCheckedKeys.find((el: any) => el.id === specialization.id)
        ) {
          defaultCheckedKeys.push(`${specialization.id}`);
        }
        //defaultCheckedKeys.push(`${specialization.id}`);
      }
      let structuredSpec = {
        title: specialization.name,
        key: `${specialization.id}`,
        value: specialization.id,
        children: [],
      };
      if (specialization.sub_domains.length) {
        // @ts-ignore
        for (let j = 0; j < specialization.sub_domains.length; j++) {
          let childSpec = specialization.sub_domains[j] as Specialization;
          let dataNode = buildDataNode(childSpec);
          // @ts-ignore
          structuredSpec.children.push(dataNode);
          // if (userSpecializations.find((el: any) => el.id === childSpec.id) || defaultOnboardingChecked) {
          //   defaultCheckedKeys.push(`${childSpec.id}`);
          // }
        }
        defaultCheckedKeys = traverseSpecializations(
          specialization.sub_domains,
          defaultCheckedKeys,
          defaultOnboardingChecked,
        );
      }
      treeData.push(structuredSpec);
    }

    setTreeData(treeData);
    setDefaultCheckedKeys(defaultCheckedKeys);
    setCurrentCheckedKeys(defaultCheckedKeys);
  };

  const onFinish = (values: any) => {
    setLoading(true);
    const employment_types: Recruiter['employment_types'] = {};
    values.employment_types?.forEach((type: string) => {
      if (type === PERM) {
        employment_types[type] = {
          fixed_fee_range: values.fixed_fee_range ?? [10000, 30000],
          percentage_fee_range: values.percentage_fee_range ?? [10, 30],
        };
      } else {
        employment_types[type] = {};
      }
    });

    axios
      .all([
        setSpecializationList({ specializations: values.specializations }),
        setSeniorityList({ seniority: values.seniority }),
        setCityList({ locations: values.locations }),
        updateRecruiter({
          preference_relevance_interval: values.preference_relevance_interval,
          industry_experiences: values.industry_experiences,
          employment_types: JSON.stringify(employment_types),
          candidate_network_location: values.candidate_network_location,
        }),
      ])
      .then((res) => {
        const token = getToken('access_token');
        if (!standalone) {
          setLoading(false);
          dispatch(getUserByToken(token));
        } else {
          token &&
            getUserInfo(token).then((res) => {
              setLoading(false);
              dispatch(updateUser(res));
            });
        }
        message.success(t('INFORMATION_SAVE_SUCCESS'), 5);
        if (!standalone) {
          history.push(DASHBOARD);
        }
      })
      .catch((e) => setLoading(false));
  };

  useEffect(() => {
    setPageLoad(true);
    async function fetchData() {
      const locationList = await getRegions();
      const seniorityList = await getSeniorityList();
      const specializationList = await getSpecializationList();
      const recruiterInfo = await getRecruiterInfo();
      const filteredSpecializations = specializationList.data.filter(
        (s: any) => s.is_hidden === false,
      );
      const employmentTypes = Object.keys(recruiterInfo?.data.employment_types)
        ?.length
        ? JSON.parse(recruiterInfo.data.employment_types)
        : {};
      setSelectedEmploymentTypes(Object.keys(employmentTypes));
      setCandidateNetworkLocation(
        recruiterInfo.data.candidate_network_location,
      );

      isOnboard
        ? form.setFieldsValue({
            locations: locationList?.data.map((e: any) => e.id),
            seniority: seniorityList?.data.map((e: any) => e.id),
            specializations: filteredSpecializations?.map((e: any) => e.id),
            preference_relevance_interval: user.preference_relevance_interval,
            industry_experiences: user.industry_experiences ?? '',
            employment_types: Object.keys(employmentTypes) ?? [],
            fixed_fee_range: employmentTypes[PERM]?.fixed_fee_range ?? [
              10000, 30000,
            ],
            percentage_fee_range: employmentTypes[PERM]
              ?.percentage_fee_range ?? [10, 30],
            candidate_network_location:
              recruiterInfo.data.candidate_network_location ?? '',
          })
        : form.setFieldsValue({
            locations: user.locations?.map((e: any) => e.id),
            seniority: user.seniority?.map((e: any) => e.id),
            specializations: user.specializations?.map((e: any) => e.id),
            preference_relevance_interval: user.preference_relevance_interval,
            industry_experiences: user.industry_experiences ?? '',
            employment_types: Object.keys(employmentTypes) ?? [],
            candidate_network_location:
              recruiterInfo.data.candidate_network_location ?? '',
            fixed_fee_range: employmentTypes[PERM]?.fixed_fee_range ?? [
              10000, 30000,
            ],
            percentage_fee_range: employmentTypes[PERM]
              ?.percentage_fee_range ?? [10, 30],
          });
      return {
        locationList,
        seniorityList,
        specializationList: filteredSpecializations,
      };
    }

    fetchData().then((res) => {
      setRegions(res.locationList.data);
      setSeniority(res.seniorityList.data);
      setSpecializations(res.specializationList);
      setSpecializationStructuredData(res.specializationList);
      setPageLoad(false);
    });
    // eslint-disable-next-line
  }, [user, form, isOnboard]);

  function updateCheckedKeys(
    parentChecked: boolean,
    node: any,
    currentCheckedKeys: any[],
    mappedCheckedKeys: any[],
  ) {
    if (node.children && node.children.length) {
      if (!parentChecked) {
        for (let child of node.children) {
          if (!currentCheckedKeys.find((el: any) => el === child.key)) {
            currentCheckedKeys.push(child.key);
            mappedCheckedKeys.push(child.key);
          }
          updateCheckedKeys(
            parentChecked,
            child,
            currentCheckedKeys,
            mappedCheckedKeys,
          );
        }
      } else {
        for (let child of node.children) {
          let elementIndex = currentCheckedKeys.findIndex(
            (el: any) => el === child.key,
          );
          if (elementIndex > -1) {
            currentCheckedKeys.splice(elementIndex, 1);
            mappedCheckedKeys.splice(elementIndex, 1);
            updateCheckedKeys(
              parentChecked,
              child,
              currentCheckedKeys,
              mappedCheckedKeys,
            );
          }
        }
      }
    }
  }

  const onCheck: TreeProps['onCheck'] = (checkedKeys, info) => {
    // @ts-ignore
    let currentCheckedKeys = checkedKeys.checked.map((key: any) => key);
    // @ts-ignore
    let mappedCheckedKeys = checkedKeys.checked.map((key: any) => key);
    let node = info.node;
    /*if (node.children && node.children.length) {
      if (!node.checked) {
        for (let child of node.children) {
          currentCheckedKeys.push(child.key);
          // @ts-ignore
          mappedCheckedKeys.push(child.key);
        }
      } else {
        for (let child of node.children) {
          let elementIndex = currentCheckedKeys.findIndex((el: any) => el === child.key);
          if (elementIndex > -1) {
            currentCheckedKeys.splice(elementIndex, 1);
            mappedCheckedKeys.splice(elementIndex, 1);
          }
        }
      }
    }*/
    updateCheckedKeys(
      node.checked,
      node,
      currentCheckedKeys,
      mappedCheckedKeys,
    );
    setCurrentCheckedKeys(currentCheckedKeys);
    form.setFieldsValue({
      specializations: mappedCheckedKeys,
    });
  };

  const fetchGooglePlaces = debounce(async (searchValue: string) => {
    if (!searchValue) {
      setGooglePlaces([]);
      return;
    }

    if (fetching) {
      return;
    }

    try {
      const places = await getGooglePlacesTextSearch(searchValue);
      setGooglePlaces(places.data.results);
    } catch (error) {
      console.error(error);
    } finally {
      setFetching(false);
    }
  }, 500);

  useEffect(() => {
    !isOnboard &&
      form.setFieldsValue({
        locations: regions?.length ? user.locations?.map((e: any) => e.id) : [],
        seniority: seniority?.length
          ? user.seniority?.map((e: any) => e.id)
          : [],
        specializations: specializations?.length
          ? user.specializations.map((e: any) => e.id)
          : [],
        preference_relevance_interval: user.preference_relevance_interval,
      });
  }, [form, user, regions, seniority, specializations, isOnboard]);

  return pageLoad ? (
    <Loader spinning={pageLoad} />
  ) : (
    <Form
      scrollToFirstError={true}
      layout="vertical"
      name="client-onboard"
      onFinish={onFinish}
      form={form}
    >
      <FormTitle text={t('CANDIDATE_TITLE')} />
      <p style={{ fontSize: '0.9rem', paddingBottom: '1rem' }}>
        {t('CANDIDATE_DESCRIPTION')}
      </p>
      <Subtitle title={t('TYPES_OF_EMPLOYMENT')} />
      <FormItem
        label={t('EMPLOYMENT_TYPE_LABEL')}
        name="employment_types"
        className="no-margin"
      >
        <SelectInput
          mode="multiple"
          size="large"
          onChange={(value) => setSelectedEmploymentTypes(value)}
          suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
        >
          {DEFAULT_EMPLOYMENT_TYPES.map((type, index) => (
            <Select.Option value={type} key={type}>
              {type}
            </Select.Option>
          ))}
        </SelectInput>
      </FormItem>
      {selectedEmploymentTypes.includes(PERM) && (
        <div style={{ padding: '2rem 0' }}>
          <Subtitle title={t('FEES')} />
          <p style={{ fontSize: '0.9rem', paddingBottom: '1rem' }}>
            {t('FEES_DESCRIPTION')}
          </p>
          <FormItem
            label={t('EMPLOYMENT_TYPE_PERMANENT_FIXED_LABEL')}
            name="fixed_fee_range"
            className="no-margin"
          >
            <StyledSlider
              id="fixed"
              tipFormatter={(value) => `€${value}`}
              step={100}
              range
              defaultValue={[10000, 30000]}
              max={50000}
            />
          </FormItem>
          <FormItem
            label={t('EMPLOYMENT_TYPE_PERMANENT_PERCENTAGE_LABEL')}
            name="percentage_fee_range"
            className="no-margin"
          >
            <StyledSlider
              id="percentage"
              range
              defaultValue={[10, 30]}
              max={30}
              tipFormatter={(value) => `${value}%`}
            />
          </FormItem>
        </div>
      )}
      <FormItem
        label={t('CANDIDATE_NETWORK_LOCATION')}
        name="candidate_network_location"
        className="no-margin"
        style={{ marginTop: '1rem' }}
      >
        <SelectInput
          showSearch
          filterOption={false}
          size="large"
          onSearch={fetchGooglePlaces}
          defaultValue={candidateNetworkLocation}
          value={candidateNetworkLocation}
          suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
          onChange={(value) => setCandidateNetworkLocation(value)}
        >
          {fetching && <Option value={''}>{t('Loading...')}</Option>}
          {!fetching && (
            <Option value={''}>
              {candidateNetworkLocation
                ? candidateNetworkLocation
                : t('-- Search for City --')}
            </Option>
          )}
          {googlePlaces.map((place, i) => (
            <Option key={i} value={place.formattedAddress ?? ''}>
              {place.formattedAddress}
            </Option>
          ))}
        </SelectInput>
      </FormItem>
      <StyledRow>
        <Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 8 }}>
          <FormItem name="specializations" rules={requiredRule(t)}>
            <StyledCheckBoxGroup name="specializations">
              <StyledSectionTitle>{t('SPECIALISM_LABEL')}</StyledSectionTitle>
              <Row>
                <StyledTree>
                  <Tree
                    checkable
                    checkStrictly
                    // defaultExpandedKeys={['0-0-0', '0-0-1']}
                    checkedKeys={currentCheckedKeys}
                    //defaultSelectedKeys={defaultCheckedKeys}
                    defaultCheckedKeys={defaultCheckedKeys}
                    onCheck={onCheck}
                    treeData={treeData}
                  />
                </StyledTree>
                {/*{specializations.map((sp) => (
                  <Col span={24} key={sp.name}>
                    <StyledCheckBoxItem value={sp.id}>
                      {sp.name}
                    </StyledCheckBoxItem>
                  </Col>
                ))}*/}
              </Row>
            </StyledCheckBoxGroup>
          </FormItem>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 8 }}>
          <FormItem name="seniority" rules={requiredRule(t)}>
            <StyledCheckBoxGroup name="seniority">
              <StyledSectionTitle>{t('SENIORITY_LABEL')}</StyledSectionTitle>
              <Row>
                {seniority.map((sn) => (
                  <Col span={24} key={sn.name}>
                    <StyledCheckBoxItem value={sn.id}>
                      {sn.name}
                    </StyledCheckBoxItem>
                  </Col>
                ))}
              </Row>
            </StyledCheckBoxGroup>
          </FormItem>
        </Col>
      </StyledRow>
      <FormItem name="locations" rules={requiredRule(t)}>
        <StyledCheckBoxGroup name="locations">
          <StyledSectionTitle>{t('LOCATIONS')}</StyledSectionTitle>
          <Row>
            {regions.map((ct) => (
              <Col
                xs={{ span: 24 }}
                md={{ span: 12 }}
                xl={{ span: 8 }}
                key={ct.name}
              >
                <StyledCheckBoxItem value={ct.id}>{ct.name}</StyledCheckBoxItem>
              </Col>
            ))}
          </Row>
        </StyledCheckBoxGroup>
      </FormItem>
      <FormItem className="no-margin">
        <StyledButton type="primary" htmlType="submit" loading={loading}>
          {!standalone ? t('NEXT_TITLE') : t('SAVE_TITLE')}
        </StyledButton>
      </FormItem>
    </Form>
  );
};

export default RecruiterOnboard;
