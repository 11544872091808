import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'antd';

import FormPopUp from '../../form/FormPopUp';
import { CandidateInfo, Certificate, Education, EmploymentType, Job, Skill } from '../../../types';
import CandidateCompany from '../CandidateCompany';
import CandidatePersonalInfo from '../CandidatePersonalInfo';
import GeneralInfo from '../GeneralInfo';
import FormItem from '../../form/FormItem';
import { requiredRule } from '../../../utils/validationRules';
import Button from '../../../UI/buttons/Button';
import { ReactComponent as CheckedIcon } from '../../../icons/checked.svg';
import { ReactComponent as ArrowLeftIcon } from '../../../icons/arrow-left.svg';
import { ReactComponent as EditIcon } from '../../../icons/edit.svg';
import { ErrorMessage } from '../../auth/LoginForm';
import Textarea from '../../../UI/inputs/Textarea';
import {
  RuleSection,
  RuleItem,
  StyledCheckbox,
  BackButton,
  Title,
  EditButton,
  ConfirmModal,
  TextWrap,
  TextBold,
  Text,
  Content,
} from './styles';
import { SkillItem, SkillItemList } from '../../jobs/singleJob/Banner/styles';
import useCandidateIntroduceModal from './hooks';
import PointsLeft from "../../recruiter/PointsLeft";
import EmploymentTypeFieldsFormSection from '../../form/shared/employment-types/EmploymentTypeFieldsFormSection';
import { mapEmploymentTypeApiToForm } from '../../form/shared/employment-types/helpers';
import CheckboxInput from '../../../UI/inputs/CheckboxInput';
import useHirePortTheme from '../../../hooks/useHirePortTheme';
import useCurrency, { areCurrenciesSimilar } from '../../../hooks/useCurrency';
import styled from 'styled-components';

const WarningText = styled.p`
  color: red;
`;

interface IntroduceModalProps {
  candidate: CandidateInfo;
  skills: Skill[];
  certificates: Certificate[];
  education: Education[],
  job: Job | null;
  jobId: string;
  backButton: boolean;
  setCandidate: React.Dispatch<React.SetStateAction<CandidateInfo | null>>;
  addToList: React.Dispatch<React.SetStateAction<string[]>>;
  languages?: string[];
  languageList: { [key: string]: string };
  employmentType?: EmploymentType;
  onConfirmFinished?: any;
}

const CandidateIntroduceModal = ({
  candidate,
  setCandidate,
  jobId,
  backButton,
  job,
  addToList,
  skills,
  certificates,
  education,
  employmentType,
  onConfirmFinished
}: IntroduceModalProps) => {
  const [t] = useTranslation();
  const currency = useCurrency(candidate.compensation_currency);
  const theme = useHirePortTheme();

  const {
    actions: {
      setVisible,
      showEditCandidate,
      onSubmit,
      onFieldsChange,
      setAgree,
      setShowConfirmModal,
    },
    data: {
      visible,
      confirmClose,
      form,
      agree,
      loading,
      error,
      showConfirmModal,
    },
  } = useCandidateIntroduceModal({
    candidate,
    setCandidate,
    job,
    jobId,
    addToList,
    skills,
    certificates,
    education,
    onConfirmFinished
  });

  const handleConfirm = () => {
    const formValues = form.getFieldsValue();
    const values = {
      ...formValues,
      compensation: {
        ...formValues.compensation,
        type: { id: employmentType?.id },
      }
    }
    onSubmit(values);
  };

  const handleSubmit = (_values: any) => {
    setShowConfirmModal(true);
  };

  const matchingCandidateEmploymentType = candidate.employment_types.find((candidateEmploymentType) => candidateEmploymentType.type.id === employmentType?.id);

  return (
    <>
      <FormPopUp
        visible={visible}
        setVisible={setVisible}
        title={t('INTRODUCE')}
        description={t('INFORMATION_CORRECT_TEXT')}
        backButton={
          backButton && (
            <BackButton onClick={() => setCandidate(null)}>
              <ArrowLeftIcon style={{ stroke: '#627D95', color: '#627D95' }} />
            </BackButton>
          )
        }
        confirmClose={confirmClose}
        large={true}
      >
        <CandidateCompany
          role={job?.title}
          company={`${t('AT')} ${job?.company?.name ? job?.company?.name : ''}`}
          image={`${job?.company?.image
            ? job.company?.image
            : process.env.PUBLIC_URL + '/images/company-logo.png'
            }`}
          companyId={job?.company?.id}
        />

        <CandidatePersonalInfo
          name={candidate?.name || ''}
          color={candidate?.color}
          role={candidate?.current_role}
          company={`${t('AT')} ${candidate?.current_company ? candidate?.current_company : ''
            }`}
          cv={candidate?.cv as string}
          linkedin={candidate?.linkedin_link as string}
        />

        <EditButton size="small" onClick={() => showEditCandidate(candidate.id)}>
          <EditIcon />
          {t('EDIT_CANDIDATE')}
        </EditButton>

        <Form
          onFinish={handleSubmit}
          layout="vertical"
          scrollToFirstError={true}
          form={form}
          onFieldsChange={onFieldsChange}
          initialValues={matchingCandidateEmploymentType ? {
            compensation: mapEmploymentTypeApiToForm(matchingCandidateEmploymentType),
          } : {}}
        >
          <Row justify="space-between">
            <Col xs={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
              <GeneralInfo
                candidate={candidate as CandidateInfo}
                isEditable={true}
              />
            </Col>
          </Row>
          <Title>{('Job fit')}</Title>

          <FormItem
            label={`${t('INTRODUCTION')} (${t('REQUIRED')})`}
            name="introduction"
            className={'text-grey'}
            rules={requiredRule(t)}
          >
            <Textarea autoSize={{ minRows: 2 }} />
          </FormItem>

          <FormItem
            label={`${t('WHY_MATCH')} (${t('REQUIRED')})`}
            name="match_reason"
            className={'text-grey'}
            rules={requiredRule(t)}
          >
            <Textarea autoSize={{ minRows: 2 }} />
          </FormItem>

          <FormItem
            label={`${t('REASON_NEXT_STEP')} (${t('REQUIRED')})`}
            name="next_step_reason"
            className={'text-grey'}
            rules={requiredRule(t)}
          >
            <Textarea autoSize={{ minRows: 2 }} />
          </FormItem>

          <FormItem
            label={`${t('COMMENTS')}`}
            name="comments"
            className={'text-grey'}
          >
            <Textarea autoSize={{ minRows: 3 }} />
          </FormItem>

          <Title>{('Compensation fit')}</Title>
          <p>
            The employment type of this job is <strong>{employmentType?.name}</strong>.
            <br />
            {
              matchingCandidateEmploymentType
                ? <>You have already provided <strong>{employmentType?.name}</strong> compensation preferences for <strong>{candidate.name}</strong>, although you can override them below:</>
                : <>You have not provided default <strong>{employmentType?.name}</strong> compensation preferences for <strong>{candidate.name}</strong>, please provide them below:</>
            }
          </p>
          {areCurrenciesSimilar(job?.compensation_currency || '', candidate.compensation_currency)
            ? <p>
              <strong>NOTE:</strong> the client has selected <strong>{job?.compensation_currency}</strong> as the default currency for this job.<br />
              Please ensure candidate compensation information below is given in that currency to avoid confusion.
            </p>
            : <WarningText>
              <strong>WARNING:</strong> the currency for this job is <strong>{job?.compensation_currency}</strong>, but compensation preferences for <strong>{candidate.name}</strong> have been recorded
              in <strong>{candidate.compensation_currency}</strong>.<br />
              Please check that candidate compensation information below is given in <strong>{job?.compensation_currency}</strong> before proceeding.
            </WarningText>
          }

          {employmentType &&
            <EmploymentTypeFieldsFormSection
              name={['compensation']}
              employmentType={employmentType}
              displayContext="introduction"
              currency={currency}
            />}

          <Row>
            <Col xs={{ span: 24 }} lg={{ span: 11 }} xl={{ span: 10 }}>
              <FormItem
                className="text-grey inline-checkbox"
                label={t('PHONE_TEXT')}
                name="need_phone"
                valuePropName="checked"
              >
                <CheckboxInput />
              </FormItem>

              <FormItem
                className="text-grey inline-checkbox"
                label={t('LAPTOP')}
                name="need_laptop"
                valuePropName="checked"
              >
                <CheckboxInput />
              </FormItem>
            </Col>
          </Row>

          <Row>
            <Col xs={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
              <RuleSection>
                <RuleItem>• {t('INTRODUCTION_RULE_1')}</RuleItem>
                <RuleItem>• {t('INTRODUCTION_RULE_2')}</RuleItem>
                <RuleItem>• {t('INTRODUCTION_RULE_3')}</RuleItem>
                <RuleItem>• {t('INTRODUCTION_RULE_4')}</RuleItem>
              </RuleSection>
            </Col>
          </Row>

          <StyledCheckbox checked={agree} onChange={() => setAgree(!agree)}>
            {t('AGREE')}
          </StyledCheckbox>

          {error && <ErrorMessage message={error} type="error" />}
          <FormItem className="text-right">
            <Button
              type="primary"
              className={'left-floated'}
              htmlType="submit"
              icon={<CheckedIcon />}
              disabled={!agree || !!error}
              loading={loading}
            >
              {t('APPOINT_TITLE')}
            </Button>
            <PointsLeft customClass='color-black left-float' points={job?.recruiter_points} />
          </FormItem>
        </Form>
      </FormPopUp >

      <ConfirmModal
        closable={false}
        visible={showConfirmModal}
        title={t('APPOINT_CANDIDATE_CONFIRM_TITLE')}
        onOk={handleConfirm}
        onCancel={() => setShowConfirmModal(false)}
        footer={[
          <Button
            type="primary"
            onClick={handleConfirm}
            loading={loading}
            icon={<CheckedIcon />}
          >
            {t('APPOINT_CANDIDATE')}
          </Button>,
          <Button type="ghost" onClick={() => setShowConfirmModal(false)}>
            {t('CANCEL_TEXT')}
          </Button>,
        ]}
      >
        <Content>
          <TextWrap>
            <TextBold>{`${t('REQUIRED_COMPETENCIES')}:`}</TextBold>
          </TextWrap>

          {job?.industry_experiences && (
            <TextWrap style={{ marginTop: '10px' }}>
              <TextBold>{`${t('INDUSTRY_EXP')}:`}</TextBold>
            </TextWrap>
          )}
          <Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 13 }}>
            <SkillItemList>
              {job?.industry_experiences && (
                <SkillItem
                  style={{ backgroundColor: theme.lozengeColor, color: '#5d7080' }}
                >
                  {job?.industry_experiences}
                </SkillItem>
              )}
            </SkillItemList>
          </Col>

          {job?.company?.excluded_companies && (
            <TextWrap style={{ marginTop: '10px' }}>
              <TextBold>{`${t('APPOINT_CANDIDATE_COMPANIES')}:`}</TextBold>
              <Text>{job?.company?.excluded_companies}</Text>
            </TextWrap>
          )}
        </Content>
      </ConfirmModal>
    </>
  );
};

export default CandidateIntroduceModal;
