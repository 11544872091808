import { AutoComplete, AutoCompleteProps } from 'antd';
import styled from 'styled-components';
import { SUGGESTED_CURRENCIES } from '../../../../hooks/useCurrency';

const StyledAutoComplete = styled(AutoComplete)`
  outline: none;
  &.ant-select-arrow {
    right: 1rem;
  }
  & .ant-select-selection-overflow {
    overflow: hidden;
  }
  &.ant-select {
    &.ant-select {
      &:not(.ant-select-customize-input) {
        & .ant-select-selector {
          padding: 4px 1rem;
          height: auto;
          border-radius: ${({ theme }) => theme.radius};
          border: solid 1px #aebeca;
          background-color: #ffffff;
          width: 100%;
        }
      }

      &.ant-select-multiple {
        & .ant-select-selector {
          padding: 5px 1rem;
        }
      }

      &.ant-select-focused,
      &.ant-select-auto-complete.ant-select-focused:not(.ant-select-disabled) {
        & .ant-select-selector {
          border-color: transparent;
          box-shadow: 0 0 8px 0 ${({ theme }) => theme.primaryColor};
        }
      }
    }
  }
  &.ant-select-multiple {
    & .ant-select-selection-item {
      line-height: 1.5rem;
      font-size: 0.75rem;
      color: #4b6276;
      padding: 0.25rem 0.5rem;
      border-radius: 0.25rem;
      background-color: ${({ theme }) => theme.lozengeColor};
    }
  }
  &.ant-select-disabled {
    &.ant-select:not(.ant-select-customize-input) {
      & .ant-select-selector {
        color: #627d95;
        border: solid 1px #aebeca;
        background-color: ${({ theme }) => theme.lozengeColor};
        cursor: not-allowed;
      }
    }
  }
  &.custom-select {
    & .ant-select-selection-placeholder {
      color: #aebeca;
    }
    & .ant-select-arrow {
      right: 0.5rem;
      width: 1.5rem;
      height: 1.5rem;
      margin-top: -0.75rem;
    }
    &.ant-select-single.ant-select {
      &:not(.ant-select-customize-input):not(.ant-select-customize-input) {
        & .ant-select-selection-search-input {
          height: 3rem;
        }
      }
    }
  }
  & .ant-select-selection-placeholder {
    color: #061c2e;
  }
`;

const CurrencySelect = (props: AutoCompleteProps) => (
  <StyledAutoComplete
    options={[
      ...Array.from(new Set(SUGGESTED_CURRENCIES.map((currency) => currency.symbol))).map((value) => ({ value })),
      ...Array.from(new Set(SUGGESTED_CURRENCIES.map((currency) => currency.code))).map((value) => ({ value })),
    ]}
    {...props}
  />
);

export default CurrencySelect;
