import { TFunction } from 'react-i18next';
import { CandidateInfo, Job, RecruiterFeeType } from '../types';
import { addDecimalPoints } from './candidate';
import { CurrencyHandler } from '../hooks/useCurrency';

const calculateRecruiterFee = (
  compensationDetails: number,
  type: 'percentage' | 'fixed',
): number => {
  let fee: number;

  if (type === 'percentage') {
    fee = compensationDetails * 0.7;
    fee = parseFloat(fee.toFixed(1));
  } else {
    fee = Math.floor(compensationDetails * 0.7);
  }

  return fee;
};

type RecruiterFeeSummarySubject = Partial<
  Pick<Job, 'company_agreement' | 'recruiter_company_agreement' | 'recruiter_compensation_type' | 'recruiter_compensation_type_tooltip' | 'employment_type' | 'earn_amount_2'>
> & Partial<
  Pick<CandidateInfo, 'et_details_freelance' | 'et_details_ctp' | 'et_gda_ctp' | 'et_cao_ctp'>
>

const getRecruiterFeeSummary = (
  t: TFunction<'translation'>,
  { curr }: CurrencyHandler,
  job: RecruiterFeeSummarySubject,
  isClient?: boolean,
) => {
  // use the details in a recruiter/company agreement, if present
  // then the details in the job flex fee, if present.
  const agreement =
    Boolean(job.company_agreement?.company || job.recruiter_company_agreement?.company) ?? false;
  const employmentType = job.employment_type?.type.id || '';
  const employmentInfo = (() => {
    if (['freelance', 'ctp'].includes(employmentType)) {
      return {
        compensationType: 'custom',
        compensationDetails: employmentType === 'freelance'
          ? (
            job.company_agreement?.et_details_freelance
            || job.recruiter_company_agreement?.et_details_freelance
            || job.et_details_freelance
          )
          : (
            job.company_agreement?.et_details_ctp
            || job.recruiter_company_agreement?.et_details_ctp
            || job.et_details_ctp
            || job.et_gda_ctp
            || job.et_cao_ctp
          )
      } as { compensationType: 'custom', compensationDetails: string };
    }
    return {
      compensationType: job.company_agreement?.compensation_type || job.recruiter_company_agreement?.compensation_type || job.recruiter_compensation_type || '',
      compensationDetails: job.company_agreement?.compensation_type_tooltip || job.recruiter_company_agreement?.compensation_type_tooltip || job.recruiter_compensation_type_tooltip
    } as { compensationType: RecruiterFeeType, compensationDetails: number };
  })();

  const hasHireportFee =
    !job.company_agreement?.compensation_type &&
    !job.recruiter_company_agreement?.compensation_type;

  switch (employmentInfo.compensationType) {
    case 'custom':
      return {
        recruiterFee: `${t('Custom')}: ${employmentInfo.compensationDetails || ''}`,
        fallbackActive: false,
        hasHireportFee,
      };
    case 'percentage':
      return {
        recruiterFee:
          isClient || agreement
            ? `${employmentInfo.compensationDetails}% ${t('of yearly salary')}`
            : `${calculateRecruiterFee(
              employmentInfo.compensationDetails,
              'percentage',
            )}% ${t('of yearly salary')}`,
        fallbackActive: false,
        hasHireportFee,
      };
    case 'fixed':
      return {
        recruiterFee:
          isClient || agreement
            ? curr(employmentInfo.compensationDetails)
            : `${t('Fixed')}: ${calculateRecruiterFee(
              employmentInfo.compensationDetails,
              'fixed',
            )}`,
        fallbackActive: false,
        hasHireportFee,
      };
  }

  // this is the fallback in case neither of the compensation types are set - use `earn_amount_2` which is based on salary brackets
  const recruiterFeeAmount = job.earn_amount_2
    ? addDecimalPoints(job.earn_amount_2.toString())
    : null;
  const recruiterFee = recruiterFeeAmount
    ? `${t('Up to')} ${curr(recruiterFeeAmount)}`
    : '-';

  return { compensationType: employmentInfo.compensationType, recruiterFee, fallbackActive: true, hasHireportFee };
};

export const getRecruiterFeeForCandidateCard = (
  t: TFunction<'translation'>,
  currFunc: CurrencyHandler['curr'],
  compensation_type?: Job['recruiter_compensation_type'],
  recruiter_compensation_type_tooltip?: Job['recruiter_compensation_type_tooltip'],
) => {
  const hasHireportFee = !compensation_type;

  if (!compensation_type || !recruiter_compensation_type_tooltip) {
    return {
      recruiterFee: '-',
      fallbackActive: true,
      hasHireportFee,
    };
  }

  switch (compensation_type) {
    case 'percentage':
      return {
        recruiterFee: `${calculateRecruiterFee(
          recruiter_compensation_type_tooltip,
          'percentage',
        )}% ${t('of yearly salary')}`,
        fallbackActive: false,
        hasHireportFee,
      };
    case 'fixed':
      return {
        recruiterFee: currFunc(calculateRecruiterFee(
          recruiter_compensation_type_tooltip,
          'fixed',
        )),
        fallbackActive: false,
        hasHireportFee,
      };
    default:
      return {
        recruiterFee: '-',
        fallbackActive: true,
        hasHireportFee,
      };
  }
};

export default getRecruiterFeeSummary;
