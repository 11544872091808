import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ReactComponent as WalletIcon } from '../../../icons/wallet2.svg';
import { ReactComponent as CalendarIcon } from '../../../icons/calendar.svg';
import { ReactComponent as ChatIcon } from '../../../icons/chat.svg';
import { ReactComponent as Clock } from '../../../icons/clock3.svg';
import { ReactComponent as DownloadIcon } from '../../../icons/download.svg';

import TextWithIcon from './TextWithIcon';
import { Job } from '../../../types';
import getRecruiterFeeSummary from '../../../utils/getRecruiterFeeSummary';
import { formatJobFeeFields } from '../../../utils/formatJobFeeFields';
import {
  ActionLink,
  ActionWrapper,
} from '../../recruitment_management/RecruiterEditAgreement';
import useCurrency from '../../../hooks/useCurrency';

type RecruiterFeeSectionProps = {
  job: Job;
  isClient: boolean;
};

const StyledText = styled(TextWithIcon)`
  margin-right: 1rem;
  min-width: 200px;
  padding: 0.5rem 0;
  & span {
    white-space: pre-line;
    color: #627d95;
  }
  & path {
    stroke: ${({ theme }) => theme.primaryColor};
  }
`;

function capitalizeFirstLetter(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

const RecruiterFeeSection = ({ job, isClient }: RecruiterFeeSectionProps) => {
  const [t] = useTranslation();
  const currency = useCurrency(job.compensation_currency);
  const recruiterFeeSummary = getRecruiterFeeSummary(t, currency, job, isClient);
  const preferrableCommuicationChannel = job.preferrable_communication_channel;
  const communnicationChannels = preferrableCommuicationChannel
    ?.split(',')
    .map((el) => capitalizeFirstLetter(el));
  const extendedFeeTerms = formatJobFeeFields(job, isClient);
  const employmentType = job.employment_type?.type.id || "";
  const isEmploymentTypePermanentLike = !['freelance', 'ctp'].includes(employmentType);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {!!recruiterFeeSummary.recruiterFee && (
          <StyledText icon={<WalletIcon />} title={t('RECRUITER_COMPENSATION')}>
            <span>
              {recruiterFeeSummary.fallbackActive &&
                !recruiterFeeSummary.recruiterFee.includes('-') ? (
                <>
                  {isClient ? (
                    <p>
                      <a href="https://help.hireport.nl/en/articles/9235927-tiered-fees">
                        {t('TIERED_FEES')}
                      </a>
                    </p>
                  ) : (
                    <p>
                      <a href="https://help.hireport.nl/en/articles/7973723-what-fee-can-i-expect-after-a-placement">
                        {t('TIERED_FEES')}
                      </a>
                      : {recruiterFeeSummary.recruiterFee}
                    </p>
                  )}
                </>
              ) : (
                recruiterFeeSummary.recruiterFee
              )}
            </span>
          </StyledText>
        )}
        {isEmploymentTypePermanentLike && !!extendedFeeTerms.payment_terms && (
          <StyledText icon={<CalendarIcon />} title={t('JOB_PAYMENT_TERMS')}>
            <span>
              {extendedFeeTerms.payment_terms} {t('DAYS')}
            </span>
          </StyledText>
        )}
        {isEmploymentTypePermanentLike && extendedFeeTerms.guarantee_period !== null &&
          extendedFeeTerms.guarantee_period > 0 && (
            <StyledText icon={<CalendarIcon />} title={t('GUARANTEE_PERIOD')}>
              <span>
                {extendedFeeTerms.guarantee_period} {t('MONTHS')}
              </span>
            </StyledText>
          )}
      </div>
      {isEmploymentTypePermanentLike && (
        <>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {!!extendedFeeTerms.restitution && (
              <StyledText icon={<CalendarIcon />} title={t('JOB_RESTITUTION')}>
                <span>
                  {extendedFeeTerms.restitution} {t('PERCENTAGE')}
                </span>
              </StyledText>
            )}
            {!!extendedFeeTerms.replacement_time && (
              <StyledText icon={<CalendarIcon />} title={t('JOB_REPLACEMENT_TIME')}>
                <span>
                  {extendedFeeTerms.replacement_time} {t('MONTHS')}
                </span>
              </StyledText>
            )}
            {(
              extendedFeeTerms.exclusivity_for_replacement === true ||
              extendedFeeTerms.exclusivity_for_replacement === false) && (
                <StyledText
                  icon={<Clock />}
                  title={t('JOB_EXCLUSIVITY_FOR_REPLACEMENT')}
                >
                  <span>
                    {extendedFeeTerms.exclusivity_for_replacement === true
                      ? t('YES')
                      : t('NO')}
                  </span>
                </StyledText>
              )}
            {!!extendedFeeTerms.restitution_payment_terms && (
              <StyledText
                icon={<CalendarIcon />}
                title={t('JOB_RESTITUTION_PAYMENT_TERMS')}
              >
                <span>
                  {extendedFeeTerms.restitution_payment_terms} {t('DAYS')}
                </span>
              </StyledText>
            )}
          </div>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {extendedFeeTerms.payment_terms_start ? (
              <StyledText
                icon={<CalendarIcon />}
                title={t('JOB_PAYMENT_TERMS_START')}
              >
                <span>{extendedFeeTerms.payment_terms_start}</span>
              </StyledText>
            ) : (
              <StyledText
                icon={<CalendarIcon />}
                title={t('JOB_PAYMENT_TERMS_START')}
              >
                <span>
                  {isClient
                    ? t('AFTER_SIGNING_CONTRACT')
                    : t('AFTER_CANDIDATE_START_DATE')}
                </span>
              </StyledText>
            )}
            {!!extendedFeeTerms.custom_restitution_agreement && (
              <StyledText
                icon={<CalendarIcon />}
                title={t('JOB_CUSTOM_RESTITUTION_AGREEMENT')}
              >
                <span>{extendedFeeTerms.custom_restitution_agreement}</span>
              </StyledText>
            )}
          </div>
        </>
      )}
      {employmentType === 'ctp' && (
        <>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <StyledText icon={<CalendarIcon />} title={t('COMPANY_AGREEMENT_ET_GDA_SHORT')}>
              <span>
                {extendedFeeTerms.et_gda_ctp || '-'}
              </span>
            </StyledText>
          </div>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <StyledText icon={<CalendarIcon />} title={t('COMPANY_AGREEMENT_ET_CAO_SHORT')}>
              <span>
                {extendedFeeTerms.et_cao_ctp || '-'}
              </span>
            </StyledText>
          </div>
        </>
      )}
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {!!extendedFeeTerms.other_information && (
          <StyledText icon={<ChatIcon />} title={t('JOB_OTHER_INFORMATION')}>
            <span>{extendedFeeTerms.other_information}</span>
          </StyledText>
        )}
      </div>
      <div>
        {communnicationChannels && communnicationChannels.length > 0 && (
          <StyledText
            icon={<ChatIcon />}
            title={t('PREFERABLE_COMMUNICATION_CHANNEL')}
          >
            {communnicationChannels.map((channel) => (
              <span>{channel}</span>
            ))}
          </StyledText>
        )}
      </div>
      <div style={{ width: '100%', display: 'flex' }}>
        {!!extendedFeeTerms.contract && (
          <ActionWrapper>
            <ActionLink
              href={extendedFeeTerms.contract as string}
              target="_blank"
              rel="noreferrer"
            >
              <DownloadIcon />
              {t('COMPANY_AGREEMENT_DOWNLOAD_CONTRACT')}
            </ActionLink>
          </ActionWrapper>
        )}
      </div>
    </div>
  );
};

export default RecruiterFeeSection;
