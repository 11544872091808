import { useMemo } from 'react';

const DEFAULT_CURRENCY = '€';
const DEFAULT_REPLACMENT_TOKEN = '¤';

export const SUGGESTED_CURRENCIES = [
  { symbol: '€', code: 'EUR' },
  { symbol: '£', code: 'GBP' },
  { symbol: '$', code: 'USD' },
  { symbol: '$', code: 'AUD' },
  { symbol: '$', code: 'CAD' },
];

export const areCurrenciesSimilar = (a: string, b: string) =>
  (a.toUpperCase() === b.toUpperCase())
  || !!SUGGESTED_CURRENCIES.find(
    ({ symbol, code }) => (symbol.toUpperCase() === a && code.toUpperCase() === b)
      || (symbol.toUpperCase() === b && code.toUpperCase() === a)
  )

export type Currency = string;

type CurrencySubject = Object | undefined | null;

export type CurrencyHandler = {
  /** Accepts a value which should be formatted as a currency and returns a formatted currency string */
  curr: (str: CurrencySubject) => string,
  /** Changes the current currency to a new currency */
  changeCurrency: (newCurrency: Currency) => CurrencyHandler,
  /** Replaces a generic currency token (defaults to '¤') with the current currency */
  currReplaceToken: (str: CurrencySubject) => string,
  /** Returns the current currency */
  value: string,
};

const createFormatter = (currency: Currency, replacementToken = DEFAULT_REPLACMENT_TOKEN) => {
  const formatter = (str: CurrencySubject) => {
    // if currency is alphanumeric render it after the value, otherwise before.
    // e.g. USD => 123 USD
    //      $   => $123
    // Note: could also do some browser locale work to figure out preference of putting
    // currency symbol before / after amount, e.g. with formatToParts or similar
    const upperFirstChar = currency[0]?.toUpperCase();
    if (upperFirstChar >= 'A' && upperFirstChar <= 'Z')
      // \xa0 = non-breaking space
      return `${str}\xa0${currency}`;
    return `${currency}${str}`;
  }
  const tokenRegexp = new RegExp(replacementToken, 'gi');
  return {
    currReplaceToken: (str: CurrencySubject) => str?.toString().replace(tokenRegexp, currency) || '',
    curr: (str: CurrencySubject) => formatter(str),
    changeCurrency: (newCurrency: Currency) => createFormatter(newCurrency || DEFAULT_CURRENCY, replacementToken),
    value: currency,
  };
}

/** Returns a set of utilities for formatting currency values */
const useCurrency: (currency?: string | undefined, replacementToken?: string) => CurrencyHandler = (currency, replacementToken = DEFAULT_REPLACMENT_TOKEN) => {
  return useMemo(() => createFormatter(
    currency || DEFAULT_CURRENCY, // WB: one day this could come from the user or company's default for example... 
    replacementToken,
  ), [currency, replacementToken]);
};

export default useCurrency;
