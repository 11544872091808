import React from 'react';
import { Slider } from 'antd';
import styled from 'styled-components';
import { SliderRangeProps } from 'antd/lib/slider';

const Styled = styled(Slider)`
  &.ant-slider-track {
    background-color: #f2f6fa;
  }

  &.ant-slider-handle {
    border-color: #f2f6fa;
  }

  &.ant-slider-handle:hover,
  .ant-slider-handle:focus {
    border-color: #f2f6fa;
  }

  &.ant-tooltip-inner {
    background-color: #f2f6fa;
    color: #fff;
    border: none;
  }

  &.ant-tooltip-arrow::before {
    border-top-color: #f2f6fa;
  }
`;

const StyledSlider = ({ ...rest }: SliderRangeProps) => {
  return (
    <Styled
      trackStyle={{
        backgroundColor: '#f2f6fa',
      }}
      {...rest}
    />
  );
};

export default StyledSlider;
