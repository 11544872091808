import React, { useState, useEffect } from 'react';
import { Col, Row, Select } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import FormItem from '../FormItem';
import { maxLengthRule, requiredRule } from '../../../utils/validationRules';
import { useTranslation } from 'react-i18next';
import { getCityList } from '../../../api/regions';
import SelectInput from '../../../UI/inputs/Select';
import Input from '../../../UI/inputs/Input';
import Textarea from '../../../UI/inputs/Textarea';
import { getRecruiterInfo } from '../../../api/recruiter';
import RadioButtonTab from '../../../UI/buttons/RadioButtonTab';
import InputNumber from '../../../UI/inputs/InputNumber';

interface PersonalProps {
  form?: any | undefined;
  setLocations?: (data: any) => void;
  setPhone: React.Dispatch<React.SetStateAction<string>>;
  setAddress?: (data: string) => void;
  phone: string;
  city?: string;
  isOnboarding?: boolean;
}

const { Option } = Select;

const PersonalInfoRecruiter = ({
  form,
  setPhone,
  phone,
  city,
  isOnboarding,
}: PersonalProps) => {
  const [cities, setCities] = useState<{ id: string; name: string }[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    getCityList().then((res) => setCities(res.data));
  }, []);
  useEffect(() => {
    cities.length &&
      form.setFieldsValue({
        city: city,
      });
  }, [cities, form, city]);
  useEffect(() => {
    getRecruiterInfo().then((res) => {
      form.setFieldsValue({
        description: res.data.description,
        about_company: res.data.about_company,
        preferable_specializations: res.data.preferable_specializations.map(
          (specialization: any) => specialization.id,
        ),
        industry_experiences: res.data.industry_experiences,
      });
    });
  }, [form]);
  return (
    <>
      <FormItem className="no-margin">
        <Row>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 11 }}
            md={{ span: 24 }}
            lg={{ span: 11 }}
          >
            <FormItem
              label={t('NAME_LABEL')}
              name="name"
              rules={[...requiredRule(t), ...maxLengthRule(128, t)]}
            >
              <Input size="large" />
            </FormItem>
          </Col>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 11, offset: 2 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 11, offset: 2 }}
          >
            <FormItem
              label={t('TITLE_TEXT')}
              name="title"
              rules={[...requiredRule(t), ...maxLengthRule(255, t)]}
            >
              <Input size="large" />
            </FormItem>
          </Col>
        </Row>
      </FormItem>

      <FormItem className="no-margin">
        <Row>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 11 }}
            md={{ span: 24 }}
            lg={{ span: 11 }}
          >
            <FormItem
              label={t('EMAIL_LABEL')}
              name="email"
              rules={[
                {
                  type: 'email',
                  message: t('INVALID_EMAIL_ERROR'),
                  required: true,
                },
              ]}
            >
              <Input size="large" disabled type="email" />
            </FormItem>
          </Col>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 11, offset: 2 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 11, offset: 2 }}
          >
            <FormItem label={t('PHONE_TEXT')}>
              <Input
                size="large"
                onChange={(e) => {
                  if (e.target.value) {
                    const regex = /^[+]?\d+$/;
                    if (regex.test(e.target.value)) {
                      setPhone(e.target.value);
                    }
                  } else {
                    setPhone(e.target.value);
                  }
                }}
                value={phone}
              />
            </FormItem>
          </Col>
        </Row>
      </FormItem>
      <FormItem
        label={t('TYPE_OF_COMPANY')}
        name="recruiter_type"
        rules={requiredRule(t)}
      >
        <RadioButtonTab
          size={'large'}
          items={[
            { name: t('FREELANCE_TITLE'), value: 'freelance' },
            { name: t('INTERIM_TITLE'), value: 'interim' },
            { name: t('AGENCY_TITLE'), value: 'agency' },
          ]}
        />
      </FormItem>
      <FormItem
        label={t('LOCATION_CITY_LABEL')}
        name="city"
        rules={requiredRule(t)}
      >
        <SelectInput
          size="large"
          filterOption={true}
          suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
        >
          {cities.map((city) => (
            <Option key={city.id} value={city.id}>
              {city.name}
            </Option>
          ))}
        </SelectInput>
      </FormItem>
      <FormItem className="no-margin">
        <Row>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 11 }}
            md={{ span: 24 }}
            lg={{ span: 11 }}
          >
            <FormItem label={t('COMPANY_TITLE')} name="company">
              <Input size="large" />
            </FormItem>
          </Col>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 11, offset: 2 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 11, offset: 2 }}
          >
            <FormItem
              label={t('NUMBER_OF_EMPLOYEES')}
              name="number_of_employees"
            >
              <InputNumber size="large" />
            </FormItem>
          </Col>
        </Row>
      </FormItem>
      <FormItem className="no-margin">
        <Row>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 11 }}
            md={{ span: 24 }}
            lg={{ span: 11 }}
          >
            <FormItem
              label={t('INDUSTRY_EXPERIENCES')}
              name="industry_experiences"
            >
              <Input size="large" />
            </FormItem>
          </Col>
        </Row>
      </FormItem>
      {!isOnboarding && (
        <FormItem label={`${t('ABOUT_YOURSELF')}`} name="description">
          <Textarea autoSize={{ minRows: 3 }} />
        </FormItem>
      )}
      {!isOnboarding && (
        <FormItem label={`${t('ABOUT_YOUR_COMPANY')}`} name="about_company">
          <Textarea autoSize={{ minRows: 3 }} />
        </FormItem>
      )}
    </>
  );
};

export default PersonalInfoRecruiter;
